import PropTypes from 'prop-types'
import cx from 'classnames'
import {useStyles} from "./style";
import {useEffect} from "react";

const Button = ({
                    children,
                    className,
                    type = 'button',
                    size = 'large',
                    rounded = false,
                    collapsed = false,
                    data = 'primary',
                    isLight = false,
                    icon,
                    iconPosition = 'right',
                    width = 220,
                    disabled = false,
                    onClick,
                    ...rest
                }) => {
    let classes = useStyles({
        size,
        data,
        icon,
        disabled,
        iconPosition,
        width,
    })

    return (
        <button
            className={cx(classes.root, className)}
            type={type}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {icon ? (
                iconPosition === 'left' ? (
                    <span>
                        {icon}
                        <span>{children}</span>
                    </span>
                ) : (
                    <span>
                        <span>{children}</span>
                        {icon}
                    </span>
                )
            ) : (
                <span>{children}</span>
            )}
        </button>
    )
}

export default Button

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    variant: PropTypes.oneOf(['filled', 'ghost', 'borderless']),
    data: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    isLight: PropTypes.bool,
    icon: PropTypes.element,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}
