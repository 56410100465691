import LoadingArea from '../../components/LoadingArea'
import Sentence from '../../components/Sentence'
import { useStyles } from './style'
import { useMemo } from 'react'
import { LEVEL } from '../../constants/app'

const SentencesContainer = ({ loading, sentences, debug, meta }) => {
    const totalScore = useMemo(
        () => Math.round((meta?.score * 100 + Number.EPSILON) * 100) / 100,
        [meta?.score],
    )
    const classes = useStyles({ score: totalScore, debug })

    const translatedSentences = useMemo(
        () => (debug ? sentences : sentences?.filter((s) => s.status === LEVEL.red)),
        [debug, sentences],
    )

    return (
        <div className={classes.sentences}>
            {loading && <LoadingArea loading={loading} />}
            {!loading && sentences?.length !== 0 && translatedSentences?.length === 0 && (
                <h3 className={classes.sentences}>No ambiguous sentences found</h3>
            )}
            {sentences && translatedSentences?.length !== 0 && (
                <>
                    {debug ? (
                        <>
                            <h2>Sentences</h2>
                            <div className={classes.meta}>
                                <p>{translatedSentences?.length} sentences found</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2>Ambiguous Sentences</h2>
                            <div className={classes.meta}>
                                <p>{translatedSentences?.length} ambiguous sentences found</p>
                            </div>
                        </>
                    )}
                </>
            )}
            {/*{remove index and add an id}*/}
            {translatedSentences?.map((sentence, i) => (
                <Sentence key={i} sentence={sentence} index={i} debug={debug} />
            ))}
        </div>
    )
}

export default SentencesContainer
