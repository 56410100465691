const BASE_URL = process.env.REACT_APP_ENDPOINT

export const httpAnalyzeText = async (data) => {
    try {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        const response = await fetch(`${BASE_URL}/analyze`, options);
        const jsonData = await response?.json();
        return jsonData;
    } catch (error) {
        //todo: handle error
        console.error('Analyze error => ', error)
    }
}
