import {createUseStyles} from "react-jss";
import {mediaQueries} from "../../theme/responsive";

export const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.flexbox.spaceBetween,
        padding: '1.5rem',
        height: theme.spacing * 8,
        '& > div': {
             ...theme.flexbox.end,
            gap: theme.spacing * 2,
            '& a': {
                fontSize: 16,
                lineHeight: "19px",
                color: theme.themeColor.link,
                cursor: "pointer",
            },
        },
        [mediaQueries.m]: {
            height: theme.spacing * 6,
            '& a': {
                fontSize: 14,
            },
            '& img': {
                height: 40
            }
        }
    }
}))