import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles((theme) => ({
    root: {
        padding: '1.5rem',
        "& > h3": {
            margin: [40, 0, 15, 0],
            fontSize: 24,
            lineHeight: "29px",
            fontWeight: 700,
            color: theme.themeColor.textPrimary,
        }
    },
    subtitle: {
        marginBottom: 20,
        color: theme.themeColor.link,
    },
    customTextArea: {
        '& textarea': {
            width: "100%", minHeight: "230px",
            fontSize: 16,
            lineHeight: "24px",
        }
    },
    textAreaFooter: {
        ...theme.grid.end,
        "& p": {
            textAlign: "end",
            fontSize: 16,
            lineHeight: "24px",
            color: theme.colors.themeFgNeutralSubtle,
            padding: [8, 0, 16]
        }
    }
}))