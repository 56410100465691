import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles((theme) => ({
    root: ({size, icon, data, disabled, iconPosition,}) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: '2px solid',
        borderRadius: 2,
        position: 'relative',
        backgroundColor: data === "primary" ? theme.themeColor.btnPrimaryBg : theme.themeColor.btnSecondaryBg,
        borderColor: data === "primary" ? theme.themeColor.btnPrimaryBorder : theme.themeColor.btnSecondaryBorder,
        color: data === "primary" ? theme.themeColor.btnPrimaryText : theme.themeColor.btnSecondaryText,
        transition: "ease-in 400ms",
        height:
            size === 'large'
                ? theme.spacing * 7
                : size === 'medium'
                    ? theme.spacing * 6
                    : theme.spacing * 5,
        width: 'max-content',
        ...(!disabled && {
            cursor: "pointer",
            '&:hover': {
                backgroundColor: data === "primary" ? theme.themeColor.btnPrimaryBgHovered : theme.themeColor.btnSecondaryBg,
                borderColor: data === "primary" ? theme.themeColor.btnPrimaryBgHovered : theme.themeColor.btnSecondaryBorderHovered,
                color: data === "primary" ? theme.themeColor.btnPrimaryText : theme.themeColor.btnSecondaryText,
                '& svg': {
                    fill: data === "primary" ? theme.themeColor.btnPrimaryText : theme.themeColor.btnSecondaryText,
                }
            },
            '&:focus': {
                outline: 0
            }
        }),
        ...(disabled && {
            cursor: "not-allowed",
            backgroundColor: data === "primary" ? theme.themeColor.btnPrimaryBgDisabled : theme.themeColor.btnSecondaryBg,
            borderColor: data === "primary" ? theme.themeColor.btnPrimaryBgBorderDisabled : theme.themeColor.btnSecondaryBorder,
            color: data === "primary" ? theme.themeColor.btnPrimaryTextDisabled : theme.themeColor.btnSecondaryText,
            opacity: data === "primary" ? 1 : 0.8,
            '&:hover': {
                backgroundColor: data === "primary" ? theme.themeColor.btnPrimaryBgDisabled : theme.themeColor.btnSecondaryBg,
                borderColor: data === "primary" ? theme.themeColor.btnPrimaryBgBorderDisabled : theme.themeColor.btnSecondaryBorder,
                color: data === "primary" ? theme.themeColor.btnPrimaryTextDisabled : theme.themeColor.btnSecondaryText,
                '& svg': {
                    fill: data === "primary" ? theme.themeColor.btnPrimaryText : theme.themeColor.btnSecondaryText,
                }
            },
            '& svg': {
                fill: data === "primary" ? theme.themeColor.btnPrimaryBgDisabled : theme.themeColor.btnSecondaryBg,
            }
        }),

        '& > span': {
            display: "grid",
            alignItems:"center",
            justifyContent:"center",
            whiteSpace: 'nowrap',
            width: '100%',
            textAlign: 'center',
            gridTemplateColumns: icon ? iconPosition === 'left' ? '24px min-content' : 'min-content 24px': '1fr',
            padding: [0, theme.spacing * 2],
            gridColumnGap: theme.spacing,
            fontWeight: 500,
            ...(size === 'large' && {
                fontSize: 18, lineHeight: "28px",
            }),
            ...(size === 'medium' && {
                fontSize: 16, lineHeight: "24px",
            }),
            ...(size === 'small' && {
                fontSize: 12, lineHeight: "16px",
            }),
        },
    }),
}))