export const breakpoints = {
    width: {
        xxxxs: 320,
        xxxs: 360,
        xxs: 375,
        xs: 414,
        s: 576,
        sm: 600,
        m: 768,
        md: 992,
        l: 1024,
        lg: 1200,
        xl: 1366,
        xxl: 1440,
        xxxl: 1536,
        xxxxl: 1920,
    },
    height: {
        xxxxs: 540,
        xxxs: 640,
        xxs: 667,
        xs: 736,
        s: 812,
        m: 864,
        l: 896,
        xl: 900,
        xxl: 926,
        xxxl: 962,
        xxxxl: 1080,
    },
}

export const mediaQueries = {
    xxxxsUp: `@media only screen and (min-width: ${breakpoints.width.xxxxs}px)`,
    xxxsUp: `@media only screen and (min-width: ${breakpoints.width.xxxs}px)`,
    xxxsUpH: `@media only screen and (min-width: ${breakpoints.width.xxxs}px) and (min-height: ${breakpoints.height.xxxxs}px)`,
    xxsUp: `@media only screen and (min-width: ${breakpoints.width.xxs}px)`,
    xxsUpH: `@media only screen and (min-width: ${breakpoints.width.xxs}px) and (min-height: 600px)`,
    xsUp: `@media only screen and (min-width: ${breakpoints.width.xs}px)`,
    sUp: `@media only screen and (min-width: ${breakpoints.width.s}px)`,
    smUp: `@media only screen and (min-width: ${breakpoints.width.sm}px)`,
    mUp: `@media only screen and (min-width: ${breakpoints.width.m}px)`,
    mdUp: `@media only screen and (min-width: ${breakpoints.width.md}px)`,
    lUp: `@media only screen and (min-width: ${breakpoints.width.l}px)`,
    lgUp: `@media only screen and (min-width: ${breakpoints.width.lg}px)`,
    xlUp: `@media only screen and (min-width: ${breakpoints.width.xl}px)`,
    xxlUp: `@media only screen and (min-width: ${breakpoints.width.xxl}px)`,
    xxxlUp: `@media only screen and (min-width: ${breakpoints.width.xxxl}px)`,
    xxxxlUp: `@media only screen and (min-width: ${breakpoints.width.xxxxl}px)`,

    xxs: `@media only screen and (max-width: ${breakpoints.width.xxs}px)`,
    s: `@media only screen and (max-width: ${breakpoints.width.s}px)`,
    m: `@media only screen and (max-width: ${breakpoints.width.m}px)`,
    lg: `@media only screen and (max-width: ${breakpoints.width.m}px)`,
}
