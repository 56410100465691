import logoLight from '../../assets/images/logo_h_light.svg'
import logoDark from '../../assets/images/logo_h_dark.svg'
import {useStyles} from "./style";
import {useAppContext, useAppDispatch} from "../../contexts/AppContext";
import {appActions, themeNames} from "../../constants/app";
import {useEffect} from "react";
import {DarkModeSwitch} from "react-toggle-dark-mode";

const Header = ()  => {
    const dispatch = useAppDispatch()
    const {theme} = useAppContext()
    const classes = useStyles()

    const toggleThemeMode = () => {
        dispatch({
            type: appActions.TOGGLE_THEME,
            payload: theme === themeNames.DARK ? themeNames.LIGHT : themeNames.DARK
        });
    }

    return (
        <div className={classes.root}>
            <img src={theme === themeNames.DARK ? logoLight : logoDark} alt={"logo"}/>
            <div>
                <DarkModeSwitch
                    style={{marginTop: 3}}
                    checked={theme === themeNames.DARK}
                    onChange={() => toggleThemeMode()}
                    sunColor={"#687076"}
                    moonColor={"#d7dbdf"}
                    size={20}
                />
                <a href={"https://translatedlabs.com/welcome"} target={"_blank"} rel="noreferrer">translated labs</a>
            </div>
        </div>
    )
}

export default Header
