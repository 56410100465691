import { forwardRef, useRef } from 'react'
import cx from 'classnames'
import {useStyles} from "./style";

/**
 * Expand the height of the input box as multiple lines of text are entered.
 */
const autoExpand = (el) => {
    setTimeout(() => {
        el.style.cssText = 'height:auto; padding:0'
        el.style.cssText = 'height:' + el.scrollHeight + 'px'
    }, 0)
}

const TextArea = forwardRef(function TextArea(
    {
        label,
        name,
        placeholder,
        helpText,
        onChange,
        onBlur,
        icon,
        iconCallback,
        touched = false,
        errors,
        inputProps,
        keyPressCallback,
        className,
        rootClassName,
        rows = 1,
        maxLength,
        ...rest
    },
    forwardedRef
) {
    const textAreaRef = useRef()
    /* Textarea must have at least one ref to autoExpand*/
    const ref = forwardedRef ?? textAreaRef

    const handleKeyPress = (e) => {
        if (typeof keyPressCallback === 'function') keyPressCallback(e)
        autoExpand(e.target)
    }

    const classes = useStyles({
        touched,
        icon,
        isSuccess: !errors && touched,
    })

    return (
        <div className={cx(classes.root, rootClassName)} {...rest}>
            {!!label && <label htmlFor={name}>{label}</label>}
            <div className={cx(classes.textAreaWrapper, className)}>
                <textarea
                    ref={ref}
                    name={name}
                    rows={rows}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={handleKeyPress}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    {...inputProps}
                />
                {/*{!errors && touched && (
                    <div className={classes.icon}>
                        <Check/>
                    </div>
                )}
                {icon && <div className={classes.icon}>{icon}</div>}*/}
            </div>
            {errors?.message && (
                <div>
                    <small className={classes.errorMessage}>
                        {errors.message}
                    </small>
                </div>
            )}
        </div>
    )
})
export default TextArea
