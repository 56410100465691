import {useStyles} from "./style";
import {useAppContext} from "../../contexts/AppContext";
import {themeNames} from "../../constants/app";

const LoadingArea = ({loading}) => {
    const {theme} = useAppContext()
    const classes = useStyles({loading,isDark: theme === themeNames.DARK});

    return (
        <div className={classes.root}>
            <div className={loading ? classes.sentenceOne : ""}/>
            <div className={loading ? classes.sentenceTwo : ""}/>
            <div/>
            <div className={loading ? classes.sentenceThree : ""}/>
            <div className={loading ? classes.sentenceFour : ""}/>

            <div className={classes.info}>
                {loading ? <>
                        <p>Please wait.</p>
                        <small> We are back-translating your words...</small>
                    </>
                    :
                    <>
                        <p>Nothing to see here!</p>
                        <small>Please type your text above and click on “Analyze”</small>
                    </>
                }
            </div>
        </div>
    );
};

export default LoadingArea;
