import { useForm } from 'react-hook-form'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Button from '../../components/Button'
import SentencesContainer from '../../components/SentencesContainer'
import TextArea from '../../components/TextArea'

import { TtsProvider } from '../../contexts/TtsContext'
import { httpAnalyzeText } from '../../http-requests/rewrite.http'

import { useStyles } from './style'

const Home = () => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, touchedFields },
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
    })
    const [debugMode, setDebugMode] = useState(false)
    const [searchParams] = useSearchParams()

    const [sentences, setSentences] = useState()
    const [meta, setMeta] = useState({})
    const [loading, setLoading] = useState(false)
    let textAreaChars = watch('text')?.length ?? 0
    const ref = useRef(null)
    const classes = useStyles()

    const onSubmit = async (data) => {
        setLoading(true)
        setSentences([])
        setMeta({})
        setDebugMode(searchParams?.get('showAll'))

        try {
            const nextData = {
                ...data,
                score_handler: process.env.REACT_APP_SCORE_HANDLER || 'open-ai',
                source: process.env.REACT_APP_SOURCE || 'en',
                target: process.env.REACT_APP_TARGET || 'it',
            }
            const { meta, sentences } = await httpAnalyzeText(nextData)
            setSentences(sentences)
            setMeta(meta)
        } catch (e) {
            console.log(e)
            //todo: improve error handling
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (loading) {
            ref?.current?.scrollIntoView()
        }
    }, [loading, searchParams])

    return (
        <TtsProvider>
            <div className={classes.root} ref={ref} id={'dio'}>
                <h3>Check if your content is ready for translation</h3>
                <p className={classes.subtitle}>
                    Discover the ambiguous sentences for translations
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextArea
                        name={'text'}
                        maxLength={process.env.REACT_APP_MAX_LENGTH}
                        placeholder={'Type the English text you want to analyze'}
                        className={classes.customTextArea}
                        errors={errors['text']}
                        touched={touchedFields['text']}
                        {...register('text')}
                    />
                    <div className={classes.textAreaFooter}>
                        <p>
                            {textAreaChars} / {process.env.REACT_APP_MAX_LENGTH}
                        </p>
                        <Button
                            type={'submit'}
                            data={'primary'}
                            size={'medium'}
                            disabled={textAreaChars === 0 || loading}
                        >
                            Analyze
                        </Button>
                    </div>

                    <SentencesContainer
                        sentences={sentences}
                        loading={loading}
                        meta={meta}
                        debug={debugMode}
                    />

                    {/* <ExplainBlock /> */}
                </form>
            </div>
        </TtsProvider>
    )
}

export default Home
