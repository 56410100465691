import PropTypes from 'prop-types'
import cx from 'classnames'
import { useStyles } from './style'

const Chip = ({
    children,
    className,
    size = 'large',
    rounded = false,
    collapsed = false,
    data = 'primary',
    isLight = false,
    width = 220,
    onClick,
    ...rest
}) => {
    let classes = useStyles({
        size,
        data,
        width,
        rounded,
    })

    return (
        <div className={cx(classes.root, className)} {...rest}>
            {children}
        </div>
    )
}

export default Chip

Chip.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    variant: PropTypes.oneOf(['filled', 'ghost', 'borderless']),
    data: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    isLight: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
