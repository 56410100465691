export const msSpeechSDKSupportedLanguages = [
    'ar-BH',
    'ar-EG',
    'ar-IQ',
    'ar-IL',
    'ar-JO',
    'ar-KW',
    'ar-LB',
    'ar-LY',
    'ar-MA',
    'ar-OM',
    'ar-PS',
    'ar-QA',
    'ar-SA',
    'ar-SY',
    'ar-TN',
    'ar-AE',
    'ar-YE',
    'bn-IN',
    'bg-BG',
    'my-MM',
    'ca-ES',
    'zh-HK',
    'zh-CN',
    'zh-TW',
    'hr-HR',
    'cs-CZ',
    'da-DK',
    'nl-BE',
    'nl-NL',
    'en-AU',
    'en-CA',
    'en-GH',
    'en-HK',
    'en-IN',
    'en-IE',
    'en-KE',
    'en-NZ',
    'en-NG',
    'en-PH',
    'en-SG',
    'en-ZA',
    'en-TZ',
    'en-GB',
    'en-US',
    'et-EE',
    'fil-PH',
    'fi-FI',
    'fr-BE',
    'fr-CA',
    'fr-FR',
    'fr-CH',
    'de-AT',
    'de-DE',
    'de-CH',
    'el-GR',
    'gu-IN',
    'he-IL',
    'hi-IN',
    'hu-HU',
    'is-IS',
    'id-ID',
    'ga-IE',
    'it-IT',
    'ja-JP',
    'jv-ID',
    'kn-IN',
    'km-KH',
    'ko-KR',
    'lo-LA',
    'lv-LV',
    'lt-LT',
    'mk-MK',
    'ms-MY',
    'mt-MT',
    'mr-IN',
    'nb-NO',
    'fa-IR',
    'pl-PL',
    'pt-BR',
    'pt-PT',
    'ro-RO',
    'ru-RU',
    'sr-RS',
    'si-LK',
    'sk-SK',
    'sl-SI',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-CU',
    'es-DO',
    'es-EC',
    'es-SV',
    'es-GQ',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PY',
    'es-PE',
    'es-PR',
    'es-ES',
    'es-UY',
    'es-US',
    'es-VE',
    'sw-KE',
    'sw-TZ',
    'sv-SE',
    'ta-IN',
    'te-IN',
    'th-TH',
    'tr-TR',
    'uk-UA',
    'uz-UZ',
    'vi-VN',
    'zu-ZA',
]

export const checkTTSAvailable = (inputLanguage) => {
    return msSpeechSDKSupportedLanguages.includes(inputLanguage)
}
