import {createUseStyles} from "react-jss";
import {mediaQueries} from "../../theme/responsive";

export const useStyles = createUseStyles((theme) => ({
    root: {
        position: "relative",
        display: "grid",
        gap: 4,
        '& > :nth-child(1)': {
            height: 46,
            background: theme.colors.themeBgNeutralSubtle,
            opacity: ({isDark}) => isDark ? 0.25 : 1
        },
        '& > :nth-child(2)': {
            height: 46,
            background: ({loading}) => loading ? theme.colors.themeBgNeutralSubtle : theme.colors.themeBgNeutralMuted,
            opacity: ({isDark}) => isDark ? 0.4 : 1
        },
        '& > :nth-child(3)': {height: 84,
            background: theme.colors.themeBgNeutralSubtle,
            opacity: ({isDark}) => isDark ? 0.25 : 1
        },
        '& > :nth-child(4)': {
            height: 46,
            background: ({loading}) => loading ? theme.colors.themeBgNeutralSubtle : theme.colors.themeBgNeutralMuted,
            opacity: ({isDark}) => isDark ? 0.4 : 1
        },
        '& > :nth-child(5)': {height: 46,
            background: theme.colors.themeBgNeutralSubtle,
            opacity: ({isDark}) => isDark ? 0.25 : 1
        },
    },
    sentenceOne: {
        animation: '$analyze 4s infinite',
    },
    sentenceTwo: {
        animation: '$analyze 4s infinite',
        animationDelay: '2000ms',
    },
    sentenceThree: {
        animation: '$analyze 4s infinite',
        animationDelay: '1000ms',
    },
    sentenceFour: {
        animation: '$analyze 4s infinite',
        animationDelay: '3000ms',
    },
    '@keyframes analyze': {
        '0%': {
            background: theme.colors.themeBgNeutralSubtle
        },
        '50%': {
            background: theme.colors.themeBorderNeutralSubtle
        },
        '100%': {
            background: theme.colors.themeBgNeutralMuted
        },
    },
    info: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        textAlign: "center",
        [mediaQueries.m]: {
            width: 280
        },
        '& p': {
            height: "auto!important",
            fontSize: 18,
            fontWeight: 500,
            lineHeight: "160%",
            color: theme.themeColor.textPrimary
        },
        '& small': {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "160%",
            color: theme.themeColor.link
        }
    }
}));