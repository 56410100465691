export const appActions = {
    TOGGLE_THEME: 'toggleTheme',
    TOGGLE_ALERT: 'toggleAlert',
    SET_ALERT: 'setAlert',
    SET_LANG: 'setLang',
}

export const languages = {
    ENGLISH: 'en',
    ITALIAN: 'it',
}

export const themeNames = {
    LIGHT: 'light',
    DARK: 'dark',
}

export const storage = {
    THEME_MODE: 'theme_mode',
}

export const LEVEL = {
    red:"red",
    green:"green",
    yellow: "yellow"
}

export const DEFAULT = 'default'
export const SUCCESS = 'success'
export const FAILURE = 'fail'
export const PENDING = 'pending'
