import {createUseStyles} from "react-jss";
import {mediaQueries} from "../../theme/responsive";

export const useStyles = createUseStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 1210,
        margin: [theme.spacing * 10, "auto", 0],
        [mediaQueries.lg]: {
            width: "auto",
            margin: [theme.spacing * 3, theme.spacing * 2, 0],
        }
    },
    footer: {
        height: 60,
        // background: ({isDarkMode}) => isDarkMode ? "#212121" : theme.colors.themeBgNeutralMuted,
        width: "100%"
    }
}))