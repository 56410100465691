import Header from "../../components/Header";
import {useStyles} from "./style";
import {useAppContext} from "../../contexts/AppContext";
import {themeNames} from "../../constants/app";

const PublicLayout = ({children}) => {
    const {theme} = useAppContext()
    const classes = useStyles({isDarkMode: theme === themeNames.DARK})

    return <>
        <div className={classes.root}>
            <Header/>
            {children}
        </div>
        <div className={classes.footer}/>
    </>
}

export default PublicLayout
