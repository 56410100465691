import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    root:{

    }
}))

const PageNotFound = ()  => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <h1>PageNotFound</h1>
        </div>
    )
}

export default PageNotFound
