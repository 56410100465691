import { createContext, useContext, useReducer } from 'react'
import {appActions, languages, storage, themeNames} from '../constants/app'

/* initial configs */
const initialState = {
    name: 'app-state',
    lang: languages.ENGLISH,
    theme: localStorage.getItem(storage.THEME_MODE) ?? themeNames.LIGHT,
    alert: { message: null, title: null, variant: 'fail', visible: false },
}
const AppContext = createContext(null)
const AppDispatchContext = createContext(null)

/* app reducer */
const appReducer = (state, { type, payload }) => {
    //console.log({'current State':state, "action": type,"payload":payload })

    switch (type) {
        case appActions.TOGGLE_THEME: {
            localStorage.setItem(storage.THEME_MODE, payload)
            return { ...initialState, theme: payload }
        }
        case appActions.TOGGLE_ALERT: {
            return { ...initialState, alert: { ...initialState.alert, visible: payload } }
        }
        case appActions.SET_ALERT: {
            return { ...initialState, alert: payload }
        }
        case appActions.SET_LANG: {
            return { ...initialState, lang: payload }
        }
        default: {
            throw Error('Unknown action: ' + type)
        }
    }
}

/* custom hooks */
export const useAppContext = () => useContext(AppContext)
export const useAppDispatch = () => useContext(AppDispatchContext)

/* contexts provider */
export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(appReducer, initialState)

    return (
        <AppContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
        </AppContext.Provider>
    )
}
