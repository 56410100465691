import cx from 'classnames'
import { useStyles } from './style'
import { useContext } from 'react'
import Volume from '../../assets/icons/Volume'
import StopCircle from '../../assets/icons/StopCircle'
import { TtsContext } from '../../contexts/TtsContext'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useAppContext } from '../../contexts/AppContext'
import { LEVEL, themeNames } from '../../constants/app'
import DiffViewer from '../DiffViewer'
import Chip from '../Chip'

//todo: remove index and add an id, and remove fake rejected classes
const Sentence = ({ sentence, index, debug }) => {
    const { width } = useWindowSize()
    const { theme } = useAppContext()
    const { playing, playedObj, ttsToggle } = useContext(TtsContext)
    const score = Math.round((sentence?.score * 100 + Number.EPSILON) * 100) / 100
    const isDarkMode = theme === themeNames.DARK
    const classes = useStyles({
        showCursor: playedObj?.id === index || !playing,
        isMobile: width <= 768,
        score: score,
        debug: debug,
        isDarkMode: isDarkMode,
    })

    return (
        <div
            className={cx(
                classes.sentence,
                sentence?.status === LEVEL.red
                    ? classes.rejected
                    : sentence?.status === LEVEL.yellow
                    ? classes.warn
                    : classes.passed,
            )}
        >
            <div
                className={classes.play}
                onClick={() => ttsToggle({ sentence: sentence?.original, id: index })}
            >
                {playedObj?.id !== index && <Volume />}
                {playedObj?.id === index && <StopCircle />}
            </div>
            <div className={classes.textResults}>
                <DiffViewer original={sentence?.original} backTranslated={sentence?.back_translated} />
                <div className={classes.backTranslated}>
                    <Chip size='small' rounded>
                        AI understood
                    </Chip>
                    {sentence?.back_translated}
                </div>
            </div>
            {debug && <span className={classes.score}> score: {score} </span>}
        </div>
    )
}
export default Sentence
