import { jss } from 'react-jss'
import { create } from 'jss'
import preset from 'jss-preset-default'
import { mediaQueries } from './responsive'
import { grid, flexbox, truncateText } from './utils'
import resetCss from './reset'
import fontRegular from '../assets/fonts/arsmaquettepro-regular.woff2'
import fontMedium from '../assets/fonts/arsmaquettepro-medium.woff2'
import fontBold from '../assets/fonts/arsmaquettepro-bold.woff2'

// Currently supported theme theme ['light', 'dark']
const getAppTheme = (theme) => {
    const spacing = 8

    const commonColors = {
        themeBgNeutralDefault: '#fff',
        themeBgNeutralSubtle: '#fbfcfd',
        themeBgNeutralMuted: '#f8f9fa',
        themeFgNeutralDefault: '#000',
        themeFgNeutralDark: '#292929',
        themeFgNeutralMuted: '#687076',
        themeFgNeutralSubtle: '#7e868c',
        themeBorderNeutralStrong: '#c1c8cd',
        themeBorderNeutralDefault: '#d7dbdf',
        themeBorderNeutralSubtle: '#E6E8EB',
        themeAccentDefault: '#0056b8',
        themeAccentDefaultDarker: '#00479a',
        themeFgErrorDefault: '#cd2b31',
        themeFgErrorSubtle: '#ffefef',
        themeFgWarningDefault:"#eba540",
        themeFgWarningSubtle: '#fef5de',
        themeFgSuccessDefault: '#19794e',
        themeFgSuccessSubtle: '#e9f9ee',
    }

    const colors = {
        ...commonColors,
        //light theme element colors
        light: {
            //main bg
            background: commonColors.themeBgNeutralDefault,
            //text
            textPrimary: commonColors.themeFgNeutralDefault,
            textSecondary: commonColors.themeFgNeutralMuted,
            //link
            link: commonColors.themeFgNeutralMuted,
            //btn primary
            btnPrimaryBg: commonColors.themeAccentDefault,
            btnPrimaryBgHovered: commonColors.themeAccentDefaultDarker,
            btnPrimaryBgDisabled: commonColors.themeBgNeutralSubtle,
            btnPrimaryBorder: commonColors.themeAccentDefault,
            btnPrimaryBgBorderDisabled: commonColors.themeBorderNeutralSubtle,
            btnPrimaryText: commonColors.themeBgNeutralDefault,
            btnPrimaryTextDisabled: commonColors.themeFgNeutralSubtle,
            //btn secondary
            btnSecondaryBg: 'transparent',
            btnSecondaryBorder: commonColors.themeBorderNeutralSubtle,
            btnSecondaryBorderHovered: commonColors.themeBorderNeutralDefault,
            btnSecondaryText: commonColors.themeFgNeutralDefault,
            //input
            inputBg: 'transparent',
            inputBgSuccess: 'transparent',
            inputBgError: 'transparent',
            inputPlaceholder: commonColors.themeFgNeutralSubtle,
            inputText: commonColors.themeFgNeutralDefault,
            inputBorder: commonColors.themeBorderNeutralDefault,
            inputBorderFocused: commonColors.themeBorderNeutralDefault,
            inputBorderHovered: commonColors.themeBorderNeutralStrong,
            inputBorderError: commonColors.themeFgErrorDefault,
            inputBorderSuccess: commonColors.themeFgSuccessDefault,
            inputBgDisabled: 'rgb(0,0,0,0.5)',
            //loading Area
        },
        //dark theme element colors
        dark: {
            background: commonColors.themeFgNeutralDark,
            //text
            textPrimary: commonColors.themeBgNeutralDefault,
            textSecondary: commonColors.themeFgNeutralSubtle,
            //link
            link: commonColors.themeBorderNeutralDefault,
            //btn primary
            btnPrimaryBg: commonColors.themeAccentDefault,
            btnPrimaryBgHovered: commonColors.themeAccentDefaultDarker,
            btnPrimaryBgDisabled: commonColors.themeBgNeutralSubtle,
            btnPrimaryBorder: commonColors.themeAccentDefault,
            btnPrimaryBgBorderDisabled: commonColors.themeBorderNeutralSubtle,
            btnPrimaryText: commonColors.themeBgNeutralDefault,
            btnPrimaryTextDisabled: commonColors.themeFgNeutralSubtle,
            //btn secondary
            btnSecondaryBg: 'transparent',
            btnSecondaryBorder: commonColors.themeBorderNeutralSubtle,
            btnSecondaryBorderHovered: commonColors.themeBorderNeutralDefault,
            btnSecondaryText: commonColors.themeFgNeutralDefault,
            //input
            inputBg: '#c7c7c705',
            inputBgSuccess: commonColors.themeFgSuccessSubtle,
            inputBgError: commonColors.themeFgErrorSubtle,
            inputPlaceholder: commonColors.themeFgNeutralSubtle,
            inputText: commonColors.themeBgNeutralDefault,
            inputBorder: commonColors.themeBorderNeutralDefault,
            inputBorderFocused: commonColors.themeBorderNeutralDefault,
            inputBorderHovered: commonColors.themeBorderNeutralStrong,
            inputBorderError: commonColors.themeFgErrorDefault,
            inputBorderSuccess: commonColors.themeFgSuccessDefault,
            inputBgDisabled: 'rgb(0,0,0,0.5)',
        },
    }

    const themeColor = colors[theme]

    /** controls ( input, label, select, textarea ) **/
    const label = {
        display: 'block',
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 3,
        textTransform: 'capitalized',
        lineHeight: '19px',
    }

    const input = {
        backgroundColor: themeColor.inputBg,
        borderTop: '0',
        borderRight: '0',
        borderLeft: '0',
        border: '1px solid',
        width: '100%',
        height: spacing * 4,
        fontSize: 14,
        padding: [0, spacing],
        color: themeColor.inputText,
        transition: 'ease-in 400ms',
        '&::placeholder': {
            color: themeColor.inputPlaceholder,
        },
        '&:disabled': {
            background: themeColor.inputBgDisabled,
        },
        '&:focus': {
            outline: 'none',
            borderColor: themeColor.inputBorder,
        },
    }

    const inputSuccess = {
        extend: 'input',
        borderColor: themeColor.inputBorderSuccess,
        '& svg': {
            color: themeColor.inputBorderSuccess,
        },
    }
    const inputWarning = {
        extend: 'input',
        borderColor: themeColor.inputBorderError,
        '& svg': {
            color: themeColor.inputBorderError,
        },
    }
    const inputError = {
        extend: 'input',
        borderColor: themeColor.inputBorderError,
        '& svg': {
            color: themeColor.inputBorderError,
        },
    }

    const controls = { label, input, inputSuccess, inputWarning, inputError }

    //globals
    jss.createStyleSheet(
        {
            ...resetCss,
            '@global html': {
                background: themeColor.background,
                transition: "ease-in 350ms"
            },
            '@font-face': [
                {
                    fontFamily: 'ARS Maquette Pro',
                    src: `url(${fontRegular}) format("woff2")`,
                    'font-weight': 'normal',
                    'font-style': 'normal',
                    'font-display': 'swap',
                },
                {
                    fontFamily: 'ARS Maquette Pro',
                    src: `url(${fontMedium}) format("woff2")`,
                    'font-weight': '500',
                    'font-style': 'normal',
                    'font-display': 'swap',
                },
                {
                    fontFamily: 'ARS Maquette Pro',
                    src: `url(${fontBold}) format("woff2")`,
                    'font-weight': '700',
                    'font-style': 'normal',
                    'font-display': 'swap',
                },
            ],
        },
        { index: 3, meta: 'globals' },
    ).attach()

    const jssInstance = create(preset())

    if (process.env.NODE_ENV === 'production') {
        jssInstance.setup({ id: { minify: true } })
    }

    return {
        spacing,
        colors,
        themeColor,
        controls,
        mediaQueries,
        grid,
        flexbox,
        truncateText,
    }
}

export default getAppTheme
