import {Outlet} from 'react-router-dom'
import PublicLayout from "../../containers/public-layout";

const PublicRoute = () => {
    //const location = useLocation()
    //const accessToken = null //todo: replace just a test

    return <PublicLayout>
        <Outlet/>
    </PublicLayout>

}

export default PublicRoute
