import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles((theme) => ({
    sentence: ({ debug }) => ({
        position: 'relative',
        ...theme.flexbox.start,
        minHeight: 48,
        gap: 12,
        padding: [20, 12],
        gridTemplateColumns: ({ isMobile }) => (isMobile ? '1fr' : '24px 1fr'),
        fontSize: 18,
        lineHeight: '24px',
        boxSizing: 'borderBox',
        color: theme.themeColor.textPrimary,
        marginBottom: debug && 12,
    }),
    rejected: {
        minHeight: 92,
        background: ({ isDarkMode, debug }) =>
            debug && (isDarkMode ? '#c4343424' : theme.colors.themeFgErrorSubtle),
        border: ({ isDarkMode, debug }) => debug && (isDarkMode ? `1px solid #b637376b` : 'unset'),
    },
    passed: {
        background: ({ isDarkMode }) =>
            isDarkMode ? '#00ec1a17' : theme.colors.themeFgSuccessSubtle,
        border: ({ isDarkMode }) => (isDarkMode ? `1px solid #007e0c8f` : 'unset'),
    },
    warn: {
        background: ({ isDarkMode }) =>
            isDarkMode ? '#ffe35317' : theme.colors.themeFgWarningSubtle,
        border: ({ isDarkMode }) => (isDarkMode ? `1px solid #9c82038f` : 'unset'),
    },
    textResults: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        gap: theme.spacing * 2,
    },
    backTranslated: {
        fontSize: 14,
        '& > div': {
            marginRight: theme.spacing,
        },
    },
    score: {
        background: ({ score }) =>
            +score < 90 ? '#cd2b31e3' : score < 97 ? '#eba540d9' : '#19794edb',
        top: '-10px',
        width: 92,
        textAlign: 'center',
        position: 'absolute',
        right: '0',
        borderRadius: '0',
        fontSize: 13,
        fontWeight: 500,
        padding: [0, 6],
        color: '#fff',
    },
    play: {
        display: ({ isMobile }) => (isMobile ? 'none' : 'inherit'),
        height: '100%',
        // marginTop: 5,
        cursor: 'pointer',
        '& path': {
            stroke: theme.themeColor.textPrimary,
        },
        '& #circle': {
            animation: '$test 4s linear infinite',
            strokeDasharray: 4,
        },
    },
    '@keyframes test': {
        to: {
            strokeDashoffset: 80,
        },
    },
}))
