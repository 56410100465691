import italian from './it'
import english from './en'
import { languages } from '../constants/app'

const resources = {
    [languages.ENGLISH]: english,
    [languages.ITALIAN]: italian,
}

export default resources
