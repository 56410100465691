import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles((theme) => ({
    root: ({ size, data, rounded }) => ({
        display: 'inline',
        border: '1px solid',
        padding: [theme.spacing / 2, theme.spacing],
        borderRadius: rounded ? 999 : 2,
        position: 'relative',
        backgroundColor:
            data === 'primary' ? theme.themeColor.btnPrimaryBg : theme.themeColor.btnSecondaryBg,
        borderColor:
            data === 'primary'
                ? theme.themeColor.btnPrimaryBorder
                : theme.themeColor.btnSecondaryBorder,
        color:
            data === 'primary'
                ? theme.themeColor.btnPrimaryText
                : theme.themeColor.btnSecondaryText,
        height:
            size === 'large'
                ? theme.spacing * 7
                : size === 'medium'
                ? theme.spacing * 6
                : theme.spacing * 3,
        width: 'max-content',
    }),
}))
