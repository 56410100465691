import React, {useEffect} from 'react'
import {ThemeProvider} from 'react-jss'
import {useAppContext, useAppDispatch} from "./contexts/AppContext";
import AppRoutes from "./routes";
import {appActions} from "./constants/app";
import getAppTheme from "./theme";

const App = () => {
    const dispatch = useAppDispatch()
    const {theme} = useAppContext()

    useEffect(() => {
        dispatch({type: appActions.SET_LANG, payload: localStorage.getItem("lang")});
    }, [dispatch])

    return (
        <ThemeProvider theme={getAppTheme(theme)}>
            <AppRoutes/>
        </ThemeProvider>
    )
}

export default App
