import {createContext, useEffect, useState} from 'react'
import * as sdk from 'microsoft-cognitiveservices-speech-sdk'
import {checkTTSAvailable} from '../utils/supportedLang'

export const TtsContext = createContext({})

export const TtsProvider = ({initialValue = {}, children}) => {
    const subscriptionKey = process.env.REACT_APP_TTS_API_KEY
    const serviceRegion = process.env.REACT_APP_TTS_API_REGION
    const [player, setPlayer] = useState(null)
    const [audioConfig, setAudioConfig] = useState(null)
    const [speechConfig, setSpeechConfig] = useState(null)
    const [synthesizer, setSynthesizer] = useState(null)
    const [cbTimeout, setCbTimeout] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [playedObj, setPlayedObj] = useState(null)
    const [tts, setTTS] = useState(initialValue)

    const initializeSynthesizer = (recognitionLanguage) => {
        if (!checkTTSAvailable(recognitionLanguage)) {
            return
        }
        const newPlayer = new sdk.SpeakerAudioDestination()
        setPlayer(newPlayer)

        const newAudioConfig = sdk.AudioConfig.fromSpeakerOutput(newPlayer)
        setAudioConfig(newAudioConfig)

        let newSpeechConfig = sdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion)
        newSpeechConfig.speechRecognitionLanguage = recognitionLanguage
        newSpeechConfig.speechSynthesisLanguage = recognitionLanguage
        setAudioConfig(newAudioConfig)

        const newSynthesizer = new sdk.SpeechSynthesizer(newSpeechConfig, newAudioConfig)
        setSynthesizer(newSynthesizer)

        return newSynthesizer
    }

    const setSynthesizerLanguage = (recognitionLanguage) => {
        if (!speechConfig) return
        const newAudioConfig = sdk.AudioConfig.fromSpeakerOutput()
        setAudioConfig(newAudioConfig)

        const newSpeechConfig = speechConfig
        newSpeechConfig.speechRecognitionLanguage = recognitionLanguage
        newSpeechConfig.speechSynthesisLanguage = recognitionLanguage
        setSpeechConfig(newSpeechConfig)

        setSynthesizer(new sdk.SpeechSynthesizer(newSpeechConfig, newAudioConfig))
    }

    const setSynthesizerSynthesisVoiceName = (synthesisVoiceName) => {
        if (!speechConfig) return
        const newAudioConfig = sdk.AudioConfig.fromSpeakerOutput()
        setAudioConfig(newAudioConfig)

        const newSpeechConfig = speechConfig
        newSpeechConfig.speechSynthesisVoiceName = synthesisVoiceName
        setSpeechConfig(newSpeechConfig)

        setSynthesizer(new sdk.SpeechSynthesizer(newSpeechConfig, newAudioConfig))
    }

    const cleanUpSynthesizer = () => {
        synthesizer?.close(() => setSynthesizer(undefined))
    }

    const ttsStop = () => {
        //console.log("STOP")
        if (player) {
            player.pause()
            cleanUpSynthesizer()
            //console.log("-----CLEAN UP-----")
        }
    }

    const handleTtsCbTimeout = (audioDuration) => {
        if(cbTimeout){
            clearTimeout(cbTimeout)
        }else{
            setCbTimeout(
                setTimeout(() => {
                    setPlaying(false)
                    setPlayedObj(null)
                }, audioDuration)
            )
        }
    }

    const ttsStart = async (inputText, setIsTtsPlayingCb, isSource) => {
        //console.log("SPEAK")
        const recognitionLanguage = 'en-US' //todo: set this paras dynamically
        const synthesizerInstance = initializeSynthesizer(recognitionLanguage)
        if (!synthesizerInstance) return

        //console.log("PLAY")
        await synthesizerInstance.speakTextAsync(inputText,
            (result) => {
                const audioDuration = result?.privAudioDuration / 10000
                const timeoutCb = setTimeout(() => {
                    setIsTtsPlayingCb()
                }, audioDuration)
                setCbTimeout(timeoutCb)
                //console.log("RESULT",result)
            },
            (err) => {
                //todo: handle error
                console.error('Error', err)
            },
        )
    }

    /**
     * playedObj: obj => {sentence: "string", id: number}
     * @returns {Promise<void>}
     */
    const ttsToggle = async (reproducedObj) => {
        const ttsUtils = tts.sourceTTS
        //todo: change with the correct text that will came form the api call
        //const source = sentence
        const newPlayedObj = reproducedObj
        if (ttsUtils) {
            //if already play stop the audio and clear the prev timeout cb
            if (playing) {
                await ttsStop()
                if(cbTimeout){
                    clearTimeout(cbTimeout)
                }
                setPlaying(false)
                //In order to handle stop and play on the same source
                if(playedObj?.id === newPlayedObj.id){
                    setPlayedObj(null)
                    return
                }
                setPlayedObj(null)
            }

            //if not already playing after another track stopped
            setPlaying(true)
            setPlayedObj(newPlayedObj)
            await ttsStart(
                newPlayedObj?.sentence,
                () => {
                    setPlaying(false)
                    setPlayedObj(null)
                },
                true,
            )
        }
    }

    useEffect(() => {
        if (subscriptionKey === '' || subscriptionKey.value === 'subscription') {
            alert('Please enter your Microsoft Cognitive Services Speech subscription key!')
            return
        }
        if (!sdk) {
            alert('Speech Recognition Speech SDK not found!')
        }
        const ttsUtilsSource = initializeSynthesizer('en-US')
        //in order to handle source and target
        setTTS({sourceTTS: ttsUtilsSource})
        //console.log("tts => ", {sourceTTS: ttsUtilsSource,})

        //clean-up
        return () => {
            cleanUpSynthesizer()
            setTTS({})
        }
        //eslint-disable-next-line
    }, [])

    return (
        <TtsContext.Provider
            value={{
                tts,
                player,
                audioConfig,
                playing,
                playedObj,
                ttsToggle,
                setSynthesizerLanguage,
                setSynthesizerSynthesisVoiceName,
            }}
        >
            {children}
        </TtsContext.Provider>
    )
}
