import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles((theme) => ({
    sentences: {
        marginTop: 40,
        display: 'grid',
        color: theme.themeColor.primary,
        gap: 4,
        '& > h2': {
            color: theme.themeColor.textPrimary,
            fontWeight: 700,
            fontSize: 32,
            lineHeight: '38px',
        },
        '& > h3': {
            color: theme.themeColor.textPrimary,
            fontWeight: 600,
            fontSize: 24,
            lineHeight: '38px',
            marginTop: '-20px'
        },
    },
    meta: {
        ...theme.flexbox.start,
        gap: 24,
        margin: [12, 0, 24],
        '& > p': {
            fontWeight: 400,
            fontSize: 18,
            lineHeight: '22px',
            color: theme.themeColor.textSecondary,
        },
    },
    stats: {
        color: theme.themeColor.textSecondary,
        '& > span': {
            //padding: "6px 10px 8px",
            color: ({ score }) =>
                +score < 90
                    ? theme.colors.themeFgErrorDefault
                    : score < 97
                    ? theme.colors.themeFgWarningDefault
                    : theme.colors.themeFgSuccessDefault,
            //borderRadius: "24px",
            //color: "#fff",
            fontWeight: 500,
        },
    },
    noResults: {
        color: theme.colors.green,
    }
}))
