import React from 'react'
import * as diff from 'diff'
import PropTypes from 'prop-types'
window.diff = diff

const styles = {
    removed: {
        color: 'black',
        backgroundColor: '#FBF719',
    },
}

const DiffViewer = ({ original = '', backTranslated = '' }) => {
    let groups = []

    groups = diff.diffWords(original, backTranslated, { ignoreCase: true })
    groups = groups.filter((a) => !a.added)

    const mappedNodes = groups.map((group) => {
        const { value, removed } = group
        let nodeStyles
        if (removed) nodeStyles = styles.removed
        return <span style={nodeStyles}>{value}</span>
    })

    return <p>{mappedNodes}</p>
}

DiffViewer.propTypes = {
    original: PropTypes.string,
    backTranslated: PropTypes.string,
}

export default DiffViewer
