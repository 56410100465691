import React from 'react'
import {createRoot} from 'react-dom/client';
import {createBrowserHistory} from 'history'
import {BrowserRouter as Router} from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import {AppProvider} from "./contexts/AppContext";
import App from './App'
import "./i18n"

const history = createBrowserHistory({basename: '/'})
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <>
        <AppProvider>
            <Router history={history} basename={'/'}>
                <App tab="home"/>
            </Router>
        </AppProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
