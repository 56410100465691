import {useRoutes} from 'react-router'
import PublicRoute from './route-guards/public-route'
import {routeNames} from './constants'
import Home from "./pages/homepage";
import PageNotFound from "./pages/not-found";

const AppRoutes = () =>
    useRoutes([
        /*{
            // Authenticated User Layout Route
        },*/
        {
            element: <PublicRoute/>, // Anonymous User Layout Route
            children: [{
                    exact: true,
                    path: routeNames.HOME,
                    element: <Home/>,
                },
                {
                    exact: true,
                    path: "/",
                    element: <Home/>,
                },
                {
                    exact: true,
                    path: routeNames.NOT_FOUND, // Not found route
                    element: <PageNotFound/>,
                },
                {
                    exact: true,
                    path:"*",
                    element: <PageNotFound/>,
                }
            ],
        },
    ])

export default AppRoutes
