const StopCircle = ({size = 24}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path id="circle" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 9H9V15H15V9Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default StopCircle;
