import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
    },
    textAreaWrapper: ({ hasError, icon, isSuccess }) => ({
        position: 'relative',
        flexGrow: 1,
        '& textarea': {
            padding: icon ? [6, 24, 6, 8] : [14, 16],
            resize: 'none',
            overflow: 'auto',
            maxHeight: theme.spacing * 20,
            minHeight: 36,
            fontSize: 14,
            background: theme.themeColor.inputBg,
            color: theme.themeColor.inputText,
            height: 'auto',
            border: `1px solid ${theme.themeColor.inputBorder}`,
            transition: "400 ms ease-in",
            '&::-webkit-scrollbar': {
                display: 'auto',
            },
            '-ms-overflow-style': 'none' /* IE and Edge */,
            scrollbarWidth: 'auto' /* Firefox */,
            '&:focus': {
                border: `2px solid ${theme.themeColor.inputBorderFocused}`,
            },
            ...(hasError && { ...theme.controls.inputError }),
        },
    }),
    errorMessage: {
        color: theme.themeColor.inputBorderError,
    },
    charsLeft: {
        position: 'absolute',
        right: 10,
        bottom: 10,
        fontSize: 10,
        color: theme.colors.themeBorderNeutralStrong,
    },
    icon: {
        position: 'absolute',
        right: 8,
        padding: [0, theme.spacing],
        bottom: 7,
    },
    statusIconWarning: {
        extend: 'icon',
        cursor: 'unset',
    },
    statusIconSuccess: {
        extend: 'icon',
        cursor: 'unset',
    },
}))
